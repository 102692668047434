/* eslint-disable ember/no-classic-classes, no-undef, ember/no-jquery */
import ArrayProxy from '@ember/array/proxy';
import { alias, filterBy } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { dasherize } from '@ember/string';
import { task, timeout } from 'ember-concurrency';
import config from 'ember-get-config';
import $ from 'jquery';
import moment from 'moment';

const { environment, dymo: { disabled: dymoDisabled } } = config;

const statusMap = {
  init: 'init',
  loadLibrary: 'loading library...',
  initLibrary: 'init library...',
  ready: 'ready'
};

const DYMO = 'dymo';
const ZEBRA = 'zebra';

const BARCODE_SUFFIX_LENGTH = 6;

export default Service.extend({

  flashMessages: service(),
  currentOrganization: service(),
  currentSession: service(),
  ajax: service(),

  _status: null,
  zebraPrinter: null,

  dateOutputFormat: alias('currentOrganization.organization.dateOutputFormat'),

  init() {
    this._super(...arguments);
    this.setProperties({
      _status: statusMap.init
    });
    this._initialize();
  },

  ready() {
    return this._status === statusMap.ready || this.zebraPrinter;
  },

  printers(quiet) {
    if (this.ready()) {
      const printers = this._printers || ArrayProxy.create({ content: [] });
      this.set('_printers', printers);

      this._getPrinters.perform(printers);

      return printers;
    }

    if (!quiet) {
      this.flashMessages.error('Printers not available');
    }

    return [];
  },

  dymoPrinters: filterBy('_printers', 'type', DYMO),
  zebraPrinters: filterBy('_printers', 'type', ZEBRA),

  _getPrinters: task(function *(printers) {
    yield timeout(1000);

    let dymoPrinters = [];
    let zebraPrinters = [];

    try {
      dymoPrinters = yield dymo.label.framework.getPrintersAsync()
        .then((printers) => {
          return printers
            .filterBy('printerType', 'LabelWriterPrinter')
            .map((printer) => {
              return {
                name: printer.name,
                type: DYMO
              };
            });
        });
    } catch (e) {
      // noop
    }

    if (this.zebraPrinter) {
      zebraPrinters.push({
        name: this.zebraPrinter.name,
        type: ZEBRA
      });
    }

    printers.set('content', dymoPrinters.concat(zebraPrinters));
  }).restartable(),

  print(barcode, template, printer, printerOptions) {
    let suffix = this._getBarcodeSuffix(barcode);
    let normalizedTemplate = template.replace(/{{BARCODE-SUFFIX}}/g, suffix);
    let barcodeImage = null;

    if (printer.type === DYMO) {
      return this
        .ajax
        .request(`${config.api_endpoint}/${config.api_namespace}/qr_codes/${barcode}`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${this.currentSession.token}` }
        })
        .then(({ data }) => {
          barcodeImage = data;
        })
        .finally(() => {
          return this._dymoPrint(barcode, barcodeImage, normalizedTemplate, printer.name, printerOptions);
        });
    }

    if (printer.type === ZEBRA) {
      return this._zebraPrint(barcode, normalizedTemplate, this.zebraPrinter, printerOptions);
    }

    return false;
  },

  _getBarcodeSuffix(barcode) {
    let suffix = barcode || '';

    if (barcode && barcode.length > BARCODE_SUFFIX_LENGTH) {
      suffix = barcode.slice(barcode.length - BARCODE_SUFFIX_LENGTH);
    }

    return suffix;
  },

  _dymoPrint(barcode, barcodeImage, template, printerName, printerOptions) {
    let xml = JSON.parse(template);
    let label = dymo.label.framework.openLabelXml(xml);
    let dateFormat = this.dateOutputFormat;

    const updateTemplate = function(key, value) {
      try {
        if (key === 'DATE' || key === 'DATE-TOP' && value) {
          value = moment(value).format(dateFormat || 'YYYY-MM-DD');
        }

        if (key === 'PATIENT-DOB' || key === 'PATIENT-DOB-TOP' && value) {
          value = moment(value).format(dateFormat || 'YYYY-MM-DD');
        }

        label.setObjectText(key, value);
      } catch (e) {
        // noop
      }
    };

    this._updateTemplate(barcode, barcodeImage, printerOptions, updateTemplate);

    label.print(printerName);

    return true;
  },

  _zebraPrint(barcode, template, printer, printerOptions) {
    let zpl = template.replace(/"/g, '');
    let dateFormat = this.dateOutputFormat;

    const updateTemplate = function(key, value) {
      try {
        if (key === 'DATE' || key === 'DATE-TOP' && value) {
          value = moment(value).format(dateFormat || 'YYYY-MM-DD');
        }

        if (key === 'PATIENT-DOB' || key === 'PATIENT-DOB-TOP' && value) {
          value = moment(value).format(dateFormat || 'YYYY-MM-DD');
        }

        zpl = zpl.replace(new RegExp(`{{${key}}}`, 'g'), value);
      } catch (e) {
        // noop
      }
    };

    this._updateTemplate(barcode, null, printerOptions, updateTemplate);

    return printer
      .getStatus()
      .then((status) => {
        if (status.paperOut) {
          this.flashMessages.error('Printer out of paper');
        } else if (status.paused) {
          this.flashMessages.error('Printer paused');
        } else if (status.headOpen) {
          this.flashMessages.error('Printer head open');
        } else if (status.ribbonOut) {
          this.flashMessages.error('Printer ribbon out of state');
        } else if (status.offline) {
          this.flashMessages.error('Printer offline');
        } else {
          printer.send(zpl,
            () => {},
            () => {
              this.flashMessages.error('Failed to print label');
            }
          );
        }
      })
      .catch((error) => {
        this.flashMessages.error('Failed to communicate with printer');
        console.error(error);
      });
  },

  _updateTemplate(barcode, barcodeImage, printerOptions, callback) {
    const barcodeShort = barcode
      .replace(/^[PT][0]+/, '')
      .padStart(6, '0');

    callback('TEXT', barcode);
    callback('BARCODE', barcode);
    callback('BARCODE-1', barcode);
    callback('BARCODE-2', barcode);
    callback('BARCODE-3', barcode);
    callback('BARCODE-TOP', barcode);
    callback('BARCODE-SHORT', barcodeShort);
    callback('BARCODE-SHORT-TOP', barcodeShort);
    callback('BARCODE-IMAGE', barcodeImage);
    callback('BARCODE-IMAGE-1', barcodeImage);
    callback('BARCODE-IMAGE-2', barcodeImage);
    callback('BARCODE-IMAGE-3', barcodeImage);

    Object
      .keys(printerOptions || {})
      .forEach((key) => {
        let dymoKey = dasherize(key).toUpperCase();

        callback(dymoKey, printerOptions[key] || 'N/A');
        callback(`${dymoKey}-1`, printerOptions[key] || 'N/A');
        callback(`${dymoKey}-2`, printerOptions[key] || 'N/A');
        callback(`${dymoKey}-3`, printerOptions[key] || 'N/A');
        callback(`${dymoKey}-TOP`, printerOptions[key] || 'N/A');
      });
  },

  _updateStatus(status) {
    this.set('_status', status);
  },

  _initialize() {
    if (environment === 'test' || dymoDisabled) {
      return;
    }

    if (this._status === statusMap.ready) {
      return;
    }

    this._initLibrary();
    this._initZebra();
  },

  _loadLibrary() {
    this._updateStatus(statusMap.loadLibrary);

    let assetUrl = this._assetMap.resolve(dymo);

    /* eslint-disable ember/jquery-ember-run */
    return $.getScript(`/${assetUrl}`)
      .then(
        () => {
          this._updateStatus(`${statusMap.loadLibrary}: DONE`);
        },
        () => {
          this._updateStatus(`${statusMap.loadLibrary}: FAILED`);
        }
      );
    /* eslint-enable ember/jquery-ember-run */
  },

  _initLibrary() {
    if (this._status === statusMap.initLibrary) {
      return;
    }

    this._updateStatus(statusMap.initLibrary);

    return dymo.label.framework.init(() => {
      this._updateStatus(`${statusMap.initLibrary}: DONE`);
      this._updateStatus(statusMap.ready);
      this.printers(true);
    });
  },

  _initZebra() {
    BrowserPrint.getDefaultDevice('printer',
      this._setDefaultZebraPrinter.bind(this),
      this._clearDefaultZebraPrinter.bind(this));
  },

  _setDefaultZebraPrinter(device) {
    const printer = new Zebra.Printer(device);
    this.set('zebraPrinter', printer);
  },

  _clearDefaultZebraPrinter() {
    this.set('zebraPrinter', null);
  },

});
