define("ember-cli-segment/services/segment", ["exports", "@ember/service", "@ember/debug", "@ember/application"], function (_exports, _service, _debug, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } /* globals FastBoot */
  class SegmentService extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "_disabled", false);
      _defineProperty(this, "_defaultPageTrackDisabled", false);
      _defineProperty(this, "_defaultIdentifyUserDisabled", false);
      _defineProperty(this, "_calledPageTrack", false);
      const isFastBoot = typeof FastBoot !== 'undefined';
      if (!this.hasAnalytics() && this.config && this.config.environment !== 'test' && !isFastBoot) {
        (false && (0, _debug.warn)('Segment is not loaded yet (window.analytics)', false, {
          id: 'ember-cli-segment.analytics-not-loaded'
        }));
      }
      if (this.config && this.config.segment) {
        const {
          defaultPageTrack,
          defaultIdentifyUser,
          enabled
        } = this.config.segment;
        this._defaultPageTrackDisabled = defaultPageTrack === false;
        this._defaultIdentifyUserDisabled = defaultIdentifyUser === false;
        this._disabled = enabled === false;
      }
    }
    get config() {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment');
    }
    hasAnalytics() {
      return !!(window.analytics && typeof window.analytics === 'object');
    }
    isEnabled() {
      return !this._disabled;
    }
    enable() {
      this._disabled = false;
    }
    disable() {
      this._disabled = true;
    }
    isPageTrackEnabled() {
      return !this._defaultPageTrackDisabled;
    }
    enableDefaultPageTrack() {
      this._defaultPageTrackDisabled = false;
    }
    disableDefaultPageTrack() {
      this._defaultPageTrackDisabled = true;
    }
    isIdentifyUserEnabled() {
      return !this._defaultIdentifyUserDisabled;
    }
    enableDefaultIdentifyUser() {
      this._defaultIdentifyUserDisabled = false;
    }
    disableDefaultIdentifyUser() {
      this._defaultIdentifyUserDisabled = true;
    }
    log() {
      if (this.config && this.config.segment && this.config.segment.LOG_EVENT_TRACKING) {
        console.info('[Segment] ', arguments); // eslint-disable-line no-console
      }
    }
    getTraits() {
      if (this.isEnabled() && this.hasAnalytics()) {
        this.log('getTraits');
        return window.analytics.user().traits();
      }
    }
    trackPageView() {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.page.apply(this, arguments);
        this._calledPageTrack = true;
        this.log('trackPageView', arguments);
      }
    }
    trackEvent(event, properties, options, callback) {
      if (this.isEnabled() && this.hasAnalytics()) {
        this.checkPageTrackCalled();
        window.analytics.track(event, properties, options, callback);
        this.log(event, properties, options);
      }
    }
    identifyUser(userId, traits, options, callback) {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.identify(userId, traits, options, callback);
        this.log('identifyUser', traits, options);
      }
    }
    addSourceMiddleware() {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.addSourceMiddleware(...arguments);
      }
    }
    addDestinationMiddleware() {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.addDestinationMiddleware(...arguments);
      }
    }

    // reset group, user traits and id's
    reset() {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.reset();
        this.log('reset');
      }
    }
    group(groupId, traits, options, callback) {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.group(groupId, traits, options, callback);
        this.log('group', traits, options);
      }
    }
    aliasUser(userId, previousId, options, callback) {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.alias(userId, previousId, options, callback);
        this.log('aliasUser', userId, previousId, options);
      }
    }
    ready() {
      if (this.isEnabled() && this.hasAnalytics()) {
        window.analytics.ready(...arguments);
      }
    }

    /**
     * Logs warning into console if trackPageView method wasn't called before tracking event
     *
     * @see https://segment.com/docs/sources/website/analytics.js/#page
     */
    checkPageTrackCalled() {
      (false && (0, _debug.warn)('[ember-cli-segment] You should call trackPageView at least once ' + 'before tracking events: ' + 'https://segment.com/docs/sources/website/analytics.js/#page', this._calledPageTrack, {
        id: 'ember-cli-segment.must-call-page'
      }));
    }
  }
  _exports.default = SegmentService;
});