/* eslint-disable ember/no-new-mixins, max-len, brace-style, ember/no-get, semi */
import Mixin from '@ember/object/mixin';
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import config from 'ember-get-config';
import { all } from 'rsvp';

const { api_namespace, api_endpoint } = config;

export default Mixin.create({

  ajax: service(),
  currentSession: service(),
  flashMessages: service(),
  segment: service(),

  isSendingFax: alias('sendFax.isRunning'),
  sendFax: task(function *(reportsOrReportRecipients) {
    if (!reportsOrReportRecipients.forEach) {
      reportsOrReportRecipients = [reportsOrReportRecipients];
    }

    const promises = reportsOrReportRecipients.map((reportOrReportRecipient) => {
      return this
        .ajax
        .request(`${api_endpoint}/${api_namespace}/tasks`, {
          method: 'POST',
          headers: { 'Authorization': `Bearer ${this.currentSession.token}` },
          data: {
            type: 'ReportFaxNotification',
            id: reportOrReportRecipient.get('reportId') || reportOrReportRecipient.get('id'),
            recipient_id: reportOrReportRecipient.get('reportId') ? reportOrReportRecipient.get('id') : null
          }
        });
    });

    yield all(promises)
      .then(() => {
        reportsOrReportRecipients.forEach((reportOrReportRecipient) => { reportOrReportRecipient.set('faxStatus', 'sent'); });
        this.flashMessages.success('Fax delivery has been scheduled');
      })
      .catch((error) => {
        this.flashMessages.error(`Fax delivery failed: ${error}`);
      });
  }).drop(),

  isSendingEmailNotification: alias('sendEmailNotification.isRunning'),
  sendEmailNotification: task(function *(report) {
    yield this
      .ajax
      .request(`${api_endpoint}/${api_namespace}/tasks`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${this.currentSession.token}` },
        data: {
          type: 'ReportEmailNotification',
          id: report.get('id')
        }
      })
      .then(() => {
        this.flashMessages.success('Email notification has been scheduled');
      })
      .catch((error) => {
        this.flashMessages.error(`Email notification failed: ${error}`);
      });
  }).drop(),

  actions: {
    sendFax(reportOrReportRecipient) {
      let segment = this.get('segment');
      this.get('sendFax').perform(reportOrReportRecipient);
      segment.trackEvent('Resend Fax Sent')
    },

    sendEmailNotification(report) {
      let segment = this.get('segment');
      this.get('sendEmailNotification').perform(report);
      segment.trackEvent('Resend Email Sent')
    }
  }
});
