/* eslint-disable max-len, quotes, no-extra-semi */
import ApplicationAdapter from 'shared/adapters/application';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ContactAdapter extends ApplicationAdapter {
  @service('ajax') oslAjax; // alternate name is needed to avoid redefining `ajax()` in @ember-data/adapter/rest
  @service currentSession;
  @service flashMessages;

  @task
  *attachPhysicianSalesAccounts(id, params) {
    let res = yield this.oslAjax.request(`${this.buildURL('sales-account-contact', id)}/attach_physician_sales_accounts`, {
      method: 'PUT',
      data: params,
      headers: { 'Authorization': `Bearer ${this.currentSession.token}` }
    }).catch((e) => {
      this.flashMessages.error(`failed to associate providers: ${e.message}`);
    });
    if (res) {
      return res.physician_sales_account_contacts.map(psac => {
        return this.store.push(this.store.normalize("physician-sales-account-contact", psac));
      });
    }
  }

  @task
  *attachProviders(id, params) {
    let res = yield this.oslAjax.request(`${this.buildURL('sales-account-contact', id)}/attach_providers`, {
      method: 'PUT',
      data: params,
      headers: { 'Authorization': `Bearer ${this.currentSession.token}` }
    }).catch((e) => {
      this.flashMessages.error(`failed to associate providers: ${e.message}`);
    });
    if (res) {
      return res.physician_sales_account_contacts.map(psac => {
        return this.store.push(this.store.normalize("physician-sales-account-contact", psac));
      });
    }
  }
};
