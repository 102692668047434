/* eslint-disable ember/no-classic-components, no-unused-vars, quotes, ember/no-classic-classes, ember/require-tagless-components, dot-notation, no-multiple-empty-lines, ember/no-component-lifecycle-hooks, ember/no-actions-hash */
import { gt } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import moment from 'moment';
import ENV from 'ember-get-config';
import layout from './template';

const TIMEOUT = ENV.environment === 'test' ? 0 : 250;
const MAX_RANGE_DAYS = 396;

const ID_TEST_TYPE = "Infectious Disease";

export default Component.extend({
  layout,

  store: service(),
  router: service(),
  infectiousDiseaseReports: service(),
  flashMessages: service(),
  settings: service(),

  classNames: ['infectious-disease__new-report'],

  providersAccounts: null,
  selectedAccounts: [],

  returnRoute: 'organization.analytics.infectious-disease.reports',

  providers: null,
  selectedProviders: null,

  testPanels: null,
  selectedTestPanel: null,

  testGenes: null,
  selectedTestGenes: [],

  disableProvider: gt('selectedAccounts.length', 1),

  lastAvailableDate: computed('organization.dateInputFormat', function() {
    const format = this.organization.dateInputFormat;
    return moment().format(format);
  }),

  searchTestGenes: task(function *(search) {
    yield timeout(TIMEOUT);

    const query = {
      organization_id: this.organization.id,
      search,
      test_type: ID_TEST_TYPE,
      fields: 'id,name',
    };

    if (this.selectedTestPanel && this.selectedTestPanel.get('id')) {
      query['test_panel_id'] = this.selectedTestPanel.get('id');
    }

    let testGenes = yield this.store.query('test-gene', query);
    set(this, 'testGenes', testGenes);

  }),

  searchTestPanels: task(function *(search) {
    yield timeout(TIMEOUT);

    const query = {
      organization_id: this.organization.id,
      search,
      test_type: ID_TEST_TYPE,
      fields: 'id,name',
    };

    let testPanels = yield this.store.query('test-panel', query);

    set(this, 'testPanels', testPanels);
  }),


  lookupWorkflow: task(function *(search) {
    yield timeout(TIMEOUT);

    const query = {
      organization_id: this.organization.id,
      filter: { status: 'completed', search },
      fields: 'id,batch-name,organization.id',
      q: search,
      page: 1,
      per_page: 10
    };

    return yield this.store.query('workflow', query);
  }),

  createReport: task(function *() {
    if (this.workflows) {
      this.model.set('workflowIds', this.workflows.mapBy('id'));
      this.model.set('workflowNames', this.workflows.mapBy('batchName'));
    }

    if (this.selectedAccounts) {
      this.model.set('providerAccountIds', this.selectedAccounts.mapBy('id'));
    }

    if (this.selectedProviders) {
      this.model.set('providerNames', this.selectedProviders.mapBy('name'));
      this.model.set('providerNpis', this.selectedProviders.mapBy('npi'));
    }

    if (this.selectedTestPanel) {
      this.model.set('testPanelIds', [this.selectedTestPanel.get('id')]);
      this.model.set('testPanelNames', [this.selectedTestPanel.get('name')]);
    }

    if (this.selectedTestGenes) {
      this.model.set('testGeneIds', this.selectedTestGenes.mapBy('id'));
      this.model.set('testGeneNames', this.selectedTestGenes.mapBy('name'));
    }

    yield this.model.save()
      .then(() => {
        this.flashMessages.success('Report generated');
        this.infectiousDiseaseReports.reload();
        this.router.transitionTo(this.returnRoute);
      }).catch(() => {
        this.flashMessages.error('An error occurred trying to generate report. Please try again.');
      });
  }).drop(),

  didReceiveAttrs() {
    this._super(...arguments);
    this.searchTestPanels.perform();
    this.searchTestGenes.perform();
  },

  actions: {
    onValidate() {
      let message = null;
      const diff = moment(this.model.get('toDate')).diff(moment(this.model.get('fromDate')), 'days', true);
      if (diff > MAX_RANGE_DAYS) {
        message = "The maximum date range is 13 months.";
      }

      return message;
    },

    providerAccountChanged(selectedAccounts) {
      set(this, 'selectedAccounts', selectedAccounts);
      set(this, 'selectedProviders', null);
    },

    providerChanged(selectedProviders) {
      set(this, 'selectedProviders', selectedProviders);
    },

    testPanelsChanged(selectedTestPanel) {
      set(this, 'selectedTestPanel', selectedTestPanel);
      set(this, 'selectedTestGenes', null);
      set(this, 'testGenes', null);

      this.searchTestGenes.perform();
    },

    testGenesChanged(selectedTestGenes) {
      set(this, 'selectedTestGenes', selectedTestGenes);
    },

    cancel() {
      this.router.transitionTo(this.returnRoute);
    },

    submit() {
      this.createReport.perform();
    }
  }
});
