define("ember-cli-raygun/initializers/ember-cli-raygun", ["exports", "ember", "@ember/debug", "@ember/polyfills", "rsvp"], function (_exports, _ember, _debug, _polyfills, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /* global Raygun */

  function _default(config) {
    let raygunConfig = config.raygun;
    if (!raygunConfig || !raygunConfig.apiKey) {
      (false && !(false) && (0, _debug.assert)("Make sure you set your Raygun API Key in config/environment.js!"));
    }
    if (raygunConfig.enabled) {
      let defaultCustomData = {
        environment: config.environment,
        appName: config.APP.name
      };
      let initOptions = {};
      if (raygunConfig.enablePulse) {
        initOptions.disablePulse = false;
      }
      if (raygunConfig.disableCrashReporting) {
        initOptions.disableErrorTracking = true;
      }
      if (raygunConfig.options) {
        initOptions = (0, _polyfills.assign)(initOptions, raygunConfig.options);
      }
      Raygun.init(raygunConfig.apiKey, initOptions, (0, _polyfills.assign)(defaultCustomData, raygunConfig.customData)).attach();
      Raygun.setVersion(config.APP.version);
      Raygun.saveIfOffline(raygunConfig.offlineEnabled);
      _ember.default.onerror = function (error) {
        // eslint-disable-next-line no-console
        console.info("Ember.onerror called");
        Raygun.send(error);
      };
      (0, _rsvp.on)('error', function (error) {
        Raygun.send(error);
      });
      let defaultErrorHandler = _ember.default.Logger.error;
      _ember.default.Logger.error = function (message, cause, stack) {
        defaultErrorHandler(message, cause, stack);
        Raygun.send(new Error(message + " (" + cause + ")"), null, {
          cause: cause,
          stack: stack
        });
      };
      if (!raygunConfig.beQuiet) {
        // eslint-disable-next-line no-console
        console.info("Ember CLI Raygun Enabled and ready to report!");
      }
    } else {
      if (!raygunConfig.beQuiet) {
        // eslint-disable-next-line no-console
        console.info("FYI: Ember CLI Raygun is currently disabled, as config.raygun.enabled is false");
      }
    }
  }
});